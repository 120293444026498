import {
  execAfterDomContentLoaded,
  roll,
  safeGetLocalStorage,
  safeSetLocalStorage,
  sleep,
  unawaited,
} from "../../util";
import { beginMusicPlayback, storageMusicKey } from "../header/header";

let elMap: {
  loadingScreen: HTMLElement;
  progressBar: HTMLElement;
  progressFill: HTMLElement;
  enterBtn: HTMLElement;
};

let progress = 1;

const hasLoadedKey = "pageHasLoadedBefore";

let completeLoadingScreen: Function;
export const loadingScreenComplete = new Promise(
  (resolve) => (completeLoadingScreen = resolve)
);

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    loadingScreen: document.getElementById("loading-screen"),
    progressBar: document.getElementById("progress-bar"),
    progressFill: document.getElementById("progress-bar-fill"),
    enterBtn: document.getElementById("enter-btn"),
  };

  // we only skip the screen if we have loaded before and the sound is turned off
  if (safeGetLocalStorage(hasLoadedKey) === true) {
    if (safeGetLocalStorage(storageMusicKey) === false) {
      unawaited(hideLoadingShowMain());
    } else {
      setupEnterBtn();
    }
  } else {
    unawaited(startProgressFill());
  }
}

async function startProgressFill() {
  document.body.classList.add("loading");
  elMap.progressBar.classList.add("visible");
  while (progress < 100) {
    progress += roll(1, 20);
    if (progress > 100) {
      progress = 100;
    }
    elMap.progressFill.style.width = `${progress}%`;
    await sleep(200);
  }

  setupEnterBtn();
}

function setupEnterBtn() {
  safeSetLocalStorage(hasLoadedKey, true);
  elMap.progressBar.classList.remove("visible");
  elMap.enterBtn.classList.add("visible");

  elMap.enterBtn.addEventListener("click", hideLoadingShowMain, { once: true });
  document.addEventListener("keypress", onDocumentKeypress);
}

async function hideLoadingShowMain() {
  elMap.loadingScreen.classList.add("slide-away");
  document.body.classList.remove("loading");
  unawaited(beginMusicPlayback());

  await sleep(500);
  completeLoadingScreen();

  await sleep(500);
  elMap.loadingScreen.style.display = "none";
}

function onDocumentKeypress(e: KeyboardEvent) {
  if (e.key === "Enter") {
    unawaited(hideLoadingShowMain());
    document.removeEventListener("keypress", onDocumentKeypress);
  }
}
